import CJ from './assets/images/cj_300.png';
import RADEO from './assets/images/rodeo_300.png';
import NOBYSTANDERS from './assets/images/no_bystanders_300.png';
import CART from './assets/images/cart_300.png';
import CART_EMPTY from './assets/images/cart_empty_300.png';

export const NAVIMAGES = {
    CJ: CJ,
    RADEO: RADEO,
    NOBYSTANDERS: NOBYSTANDERS,
    CART: CART,
    CART_EMPTY: CART_EMPTY
}
