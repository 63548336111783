import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { ShopContext } from "../../context/shop-context";
import './product.css';

export const Product = (props) =>  {
    const {id, productName, price, productImages, productDescription} = props.data
    const {addToCart,cartItems} = React.useContext(ShopContext)
    const cartItemCount = cartItems[id]
    return (
        <div key={id} className="product">
        {/* <b>{productName}</b> */}
        <div className="product-carousel">
            <Carousel >
                {productImages.map((productImage, index) => (    
                    <Carousel.Item key={index} interval={1000}>
                        <img src={productImage} alt="logo"/>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
        
        <div className='text'>
            <b>${price}</b>
        </div>
        <div className='text'>
          <button className="image-button" id="imageButton" onClick={() => addToCart(id)}>
            <b>
                Add to cart
            </b></button>
        </div>
        <div className='text'>
        <b>Free shipping</b>
        </div>
        <div className='text'>
        <b>{productDescription}</b>
        </div>
      </div>
      );
  };