import React, { useContext }from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import { ShopContext } from "../context/shop-context";
import { NAVIMAGES } from '../navimages';
export const Navbar = () => {
    const { getNumberOfItemsInCart } = useContext(ShopContext);
    const totalItemsInCart = getNumberOfItemsInCart();
    return (
        <div className="navbar">
            <Link to="/">
                <div className='home'>
                    <img src={NAVIMAGES.CJ} alt="logo" className="logo" />
                    <img src={NAVIMAGES.RADEO} alt="logo" className="logo" />
                    <img src={NAVIMAGES.NOBYSTANDERS} alt="logo" className="logo" />
                </div>
            </Link>
            <Link to="/cart">
                {/* {totalAmount > 0 ? (<img src={NAVIMAGES.CART} alt="logo" className="logo" />):
                (<img src={NAVIMAGES.CART_EMPTY} alt="logo" className="logo" />)} */}
                <div className='cart'>
                    <img src={NAVIMAGES.CART_EMPTY} alt="logo" className="logo-cart" />    
                    {totalItemsInCart > 0 ? (<div className="top-left">{totalItemsInCart}</div>) : (<></>)}
                    
                </div>
                           
            </Link>
        </div>
    )    
}