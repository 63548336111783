import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/navbar';
import { Shop } from './pages/shop/shop';
import { Cart } from './pages/cart/cart';
import { ShopContextProvider } from './context/shop-context';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-background">
        <ShopContextProvider>
          <Router>
            <Navbar />
            <Routes>
              <Route key='shop' path='/' element={<Shop/>}/>
              <Route key='shop' path='/cart' element={<Cart/>}/>
            </Routes>
          </Router>
        </ShopContextProvider>
          
        </div>
      </header>
    </div>
  );
}

export default App;
