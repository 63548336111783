import React, { useContext } from 'react';
import './cart-item.css';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import { ShopContext } from "../../context/shop-context";

export const CartItem = (props) => {
   const { id, productName, price } = props.data;
   const { cartItems, addToCart, removeFromCart } = useContext(ShopContext);
   return (
      <div>
         <Card className="cartItem">
            <Table className="table" striped bordered hover>
               <thead>
                  <tr>
                     <th><div className="description">Product</div></th>
                     <th><div className='amount'>Price</div></th>
                     <th><div className='amount'>Subtotal</div></th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td><div className="description"><p>{productName}</p></div></td>
                     <td><div className="amount"><p>{"$" + price}</p></div></td>

                     <td><div className="amount"><p>{"$" + price * cartItems[id]}</p></div></td>
                  </tr>
                  <tr>
                                
                     <td  colSpan="3" ><div className='quantity'>
                        <button className="buttonQty" onClick={() => removeFromCart(id)}><p> - </p></button>
                        <input value={cartItems[id]} disabled />
                        <button className="buttonQty" onClick={() => addToCart(id)}><p> + </p></button>
                     </div></td></tr>
               </tbody>
            </Table>

         </Card>
      </div>);
};