import React , { useContext, useState } from 'react';
import './cart.css';
import { CartItem } from "./cart-item";
import { PRODUCTS } from '../../products';
import { ShopContext } from "../../context/shop-context";
import Alert from 'react-bootstrap/Alert';
import PayPal from "../../components/PayPal";
import Table from 'react-bootstrap/Table';

export const Cart = () => {
  const { cartItems,getNumberOfItemsInCart,getTotalCartAmount,getTotalTax,getTotalCartAmountWithTax,checkout } = useContext(ShopContext);
  const [ payment, setPayment] = useState(false);
  const [ paymentStatus, setPaymentStatus] = useState('');
  const totalItemsInCart = getNumberOfItemsInCart();
  const totalAmount = getTotalCartAmount();
  const shipping = 0;
  const tax = getTotalTax();
  const total = getTotalCartAmountWithTax();
  //round total to floor decimal number
  total.toFixed(2);
  return (
   
    // <div className="cart">
    //   
    // </div>
    <div className="cart">
        {totalItemsInCart>0?(
          <>
            {PRODUCTS.map((product) => {
              if (cartItems[product.id] !== 0) {
                return <CartItem key={product.id} data={product} />;
              }
            })}
            {/* <div className="amount"><b>Total: {"$" + totalAmount}</b></div> */}
            <div className="summary">
            <Table className="table" striped bordered hover>
              <tbody>
                <tr>
                  <td><div className='description'>Items: </div></td>
                  <td>
                    <div className='amount'>{"$" + totalAmount.toFixed(2)}</div>
                  </td>
                </tr>
                <tr>
                  <td><div className='description'>Shipping & handling: </div></td>
                  <td><div className='amount'>{"$" + shipping.toFixed(2)}</div>
                  </td>
                </tr>
                <tr>
                  <td><div className='description'>Total before tax: </div></td>
                  <td><div className='amount'>{"$" + (totalAmount+shipping).toFixed(2)}</div>
                  </td>
                </tr>
                <tr>
                  <td><div className='description'>Estimated tax to be collected: </div></td>
                  <td><div className='amount'>{"$" + tax.toFixed(2)}</div>
                  </td>
                </tr>
                <tr>
                  <td><div className='description total'><b>Order total: </b></div></td>
                  <td><div className='amount total'><b>{"$" + total.toFixed(2)}</b></div>
                  </td>
                </tr>
                </tbody>
            </Table>
            </div>
            
            {
              paymentStatus === '' ? (
                <div className="checkout">
                {
                  payment ? (
                    <PayPal key="paypal" setPaymentStatus = {setPaymentStatus}
                      totalAmount = {total} cartItems = {cartItems}
                    />
                  ) : (
                    <>
                    <button className="image-button" id="imageButton" onClick={() => {
                      setPayment(true);
                      setPaymentStatus('');
                    }}><b>Checkout</b></button>
                    </>

                    
                  )
                }
                <button className="image-button" id="cancel" onClick={() => {
                      window.location.href = "/";
                    }}><b>Cancel</b></button>
              </div>
              ) : (paymentStatus === 'success' ? (
                <div className='alert'>
                  <Alert key={'success'} variant={'success'} onClick={() => {
                      checkout();
                      window.location.href = "/";
                    }} dismissible>
                        <b>Transaction Successful!</b>
                  </Alert>
                </div>
              ) : (
                <div className='alert'>
                    <Alert key={'failure'} variant={'danger'} onClick={() => {
                      setPayment(false);
                    }} dismissible>
                          <b>Transaction Failed!</b>
                    </Alert>
                </div>
              ))
            }
            
            

          </>
        ):(
          <div className='summary'>
            <div className='alert'>
            <h1>Your Shopping Cart is Empty</h1>
            <button className="image-button" id="cancel" onClick={() => {
                      window.location.href = "/";
                    }}><b>Home</b></button>
            </div>
          </div>
          
        )}
        
    </div>
  )
};