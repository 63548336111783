import product1 from "./assets/products/travis_left_300.png";
import product2 from "./assets/products/travis_front_300.png";
import product3 from "./assets/products/travis_right_300.png";

export const PRODUCTS = [
    {
        id: 0,
        productName: "RODEO",
        price: "30.00",
        productImages: [product1,product2,product3],
        productDescription: "124 piece set"
    }
]