import React from 'react';
import './shop.css';
import { Product } from "./product";
import { PRODUCTS } from '../../products'; 
export const Shop = () => {
    return (
      <div className="shop">
        <div className="shop-items">
          {PRODUCTS.map((product) => (
            <Product key={product.id} data={product}/>
          ))}
        </div>
      </div>
      );
  };