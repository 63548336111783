import React, { useState } from 'react';
import { PRODUCTS } from '../products';

export const ShopContext = React.createContext(null);
const getDefaulCart = () => {
    let cart = {};
    for (let i = 0; i <= PRODUCTS.length; i++) {
        cart[i] = 0;
    }
    return cart;
}

export const ShopContextProvider = (props) => {
    const [cartItems, setCartItems] = useState(getDefaulCart());

    const getTotalCartAmount = () => {
      let totalAmount = 0;
      for (const item in cartItems) {
        if (cartItems[item] > 0) {
          let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
          totalAmount += cartItems[item] * itemInfo.price;
        }
      }
      return totalAmount;
    };

    const getTotalTax = () => {
      let totalAmount = 0;
      for (const item in cartItems) {
        if (cartItems[item] > 0) {
          let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
          totalAmount += cartItems[item] * itemInfo.price;
        }
      }
      return totalAmount*0.1025;
    }

    const getTotalCartAmountWithTax = () => {
      let totalAmount = 0;
      totalAmount = getTotalCartAmount() + getTotalTax();
      totalAmount = Math.floor(totalAmount * 100.0) / 100.0
      return totalAmount;
    };


    const getNumberOfItemsInCart  = () => {
        let numberOfItems = 0;
        for (const item in cartItems) {
          if (cartItems[item] > 0) {
            numberOfItems += cartItems[item];
          }
        }
        return numberOfItems;
    };

    const addToCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    };

    const removeFromCart = (itemId) => {
        if(cartItems[itemId] === 1) {
          return;
        }
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    };

    const checkout = () => {
        setCartItems(getDefaulCart());
    };

    console.log(cartItems);
    const contextValue = { cartItems, addToCart, removeFromCart, getTotalCartAmount, getTotalTax,getTotalCartAmountWithTax, getNumberOfItemsInCart, checkout}
    return <ShopContext.Provider value={contextValue}>{props.children}</ShopContext.Provider>;
};