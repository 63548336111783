import React, { useRef, useEffect } from "react";
import { PRODUCTS } from "../products";
export default function Paypal({setPaymentStatus,totalAmount,cartItems}) {
  const paypal = useRef();
  const purchase_units = [];
  PRODUCTS.map((product) => {
    if (cartItems[product.id] !== 0) {
      purchase_units.push({
        description: product.productName,
        amount: {
          currency_code: "USD",
          value: (totalAmount).toFixed(2),
        },
      });
    }
  });
  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          console.log('data',data);
          console.log('totalAmount',totalAmount);
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: purchase_units
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log(order);
          setPaymentStatus('success')
        },
        onError: (err) => {
          console.log(err);
          setPaymentStatus('failed')
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div key='paypal'>
      <div key='paypal1' ref={paypal}></div>
    </div>
  );
}
